import React from 'react';

import Layout from '../../components/Layout';
import HeaderSection from '../../components/sections/HeaderSection';
import SEO from '../../components/SEO';
import HeaderImage from '../../components/images/works/wohin-du-willst/Header';
import TestimonialImage from '../../components/images/works/wohin-du-willst/Testimonial';
import IPhoneImage from '../../components/images/works/wohin-du-willst/IPhone';
import TaskList from '../../components/pages/works/TaskList';
import ProjectFactSheet from '../../components/pages/works/ProjectFactSheet';

import PhoneIcon from '../../images/icons/phone.svg';
import RocketIcon from '../../images/icons/rocket.svg';
import LightBulbIcon from '../../images/icons/light-bulb.svg';

import AmazonWebServices from '../../images/career/technologies/amazon-web-services.svg';
import AngularJS from '../../images/career/technologies/angularjs.svg';
import Docker from '../../images/career/technologies/docker.svg';
import Groovy from '../../images/career/technologies/groovy.png';
import Jenkins from '../../images/career/technologies/jenkins.png';
import Spring from '../../images/career/technologies/spring.png';
import Technologies from '../../components/pages/works/Technologies';

const WohinDuWillst = () => (
  <Layout>
    <SEO title="Wohin·Du·Willst" keywords={[]} />
    <HeaderSection>
      <>
        <h1>
          Wohin·Du·Willst
        </h1>
        <h2>
          Mobilitätsapp der DB Regio Bus
        </h2>
        <div className="flex flex-row w-full mt-8">
          <p className="w-full md:w-2/3">
            Wohin·Du·Willst ist die Mobilitätsapp der DB Regio Bus für den ländlichen Raum. Seit 2016 begeleitet hochzehn das Projekt als Partner in der technischen Umsetzung.
          </p>
        </div>
      </>
    </HeaderSection>
    <div className="flex flex-col">
      <div className="flex flex-row w-full flex-wrap mt-2">
        <div className="flex flex-col w-full md:w-3/5">
          <HeaderImage />
        </div>
        <TaskList
          tasks={[
            {
              name: 'Produktmanagement',
              icon: LightBulbIcon,
            },
            {
              name: (
                <>
                  Backend Entwicklung
                  <br />
                  Web Entwicklung
                  <br />
                  Infrastruktur
                  <br />
                  Admin-Oberfläche
                </>
              ),
              icon: RocketIcon,
            },
            {
              name: 'iOS & Android App',
              icon: PhoneIcon,
            },
          ]}
        />
      </div>
      <ProjectFactSheet
        facts={[
          {
            title: 'Kunde',
            text: 'DB Regio Bus',
          },
          {
            title: 'Zeitraum',
            text: 'seit 2016',
          },
          {
            title: 'Technologien',
            text: (
              <Technologies
                images={[
                  Spring,
                  AmazonWebServices,
                  AngularJS,
                  Docker,
                  Groovy,
                  Jenkins,
                ]}
              />
            ),
          },
        ]}
      />
      <div className="flex flex-col mt-8 text-justify">
        <h3 className="my-4">
          Story
        </h3>
        <p>
          Die Mobilitätsapp Wohin·Du·Willst wurde 2016 von der DB Regio Bus gelauncht und seitdem kontinuierlich weiterentwickelt. Seit 2016 hat hochzehn die Entwicklung des Backends vorangetrieben. Ab Anfang 2018 hat hochzehn die Gesamtentwicklung der App inklusive Produktmanagement, App-Entwicklung und Backend-Entwicklung übernommen.
        </p>
        <h3 className="my-4">
          Technische Details
        </h3>
        <p>
          Wir haben die bereits vorhandene API verbessert und funktional stark erweitert. Dabei haben wir Schnittstellen mehrerer Drittanbieter integriert und für die API-Konsumenten (iOS und Android-Apps) nutzbar gemacht. Besondere Aufmerksamkeit haben wir auf Rückwärtskompatibilität gelegt, um alte App-Versionen jederzeit weiterhin zu unterstützen.
          <br />
          <br />
          Das Backend wurde von einer Single-Server-Architektur auf eine skalierbare, hochverfügbare Multi-Server-Lösung migriert. Innerhalb der Amazon Web Services konnten wir eine Zero-Downtime- und Continuous-Delivery-Infrastruktur bereitstellen, die den Nutzern jederzeit Zugriff auf die Mobilitätsdaten zusicherte. Dazu haben wir unter anderem Jenkins, Docker, Consul und nginx eingesetzt. Auf einer baugleichen Staging-Umgebung konnten uneingeschränkte Funktionstests der Apps mit einem produktionsnahen Backend durchgeführt werden.
          <br />
          <br />
          Neben dem Backend hat hochzehn für DB Regio Bus den Konfigurator entwickelt - eine Webanwendung über die die verschiedenen Mandanten der App administriert werden können. Dazu haben wir clientseitig Angular 1.5 sowie Bootstrap eingesetzt und die Applikation mittels nginx und Docker bereitgestellt.
        </p>
      </div>
      <div className="flex flex-row flex-wrap mt-16 justify-between">
        <div className="flex flex-col w-full md:w-1/3">
          <IPhoneImage />
        </div>
        <div className="flex flex-row flex-wrap-reverse w-full md:w-1/2">
          <div className="flex flex-col">
            <p>
              Mario König,
              <br />
              Produktmanager DB Regio Bus:
            </p>
            <div className="mt-2 text-xl font-light italic">
              „hochzehn ist bereits seit 2016 unser Umsetzungspartner für das Projekt Wohin·Du·Willst.
              Besonders hervorzuheben sind die agile Projektdurchführung und die ganzheitliche Betreuung:
              Sowohl bei der Konzeption des übergeordneten Geschäftsmodells als auch bei Entwicklung
              und Produktmanagement liefert hochzehn stets überzeugende Lösungen. Wir sind sehr zufrieden
              und freuen uns auf die weitere Zusammenarbeit."
            </div>
          </div>
          <div className="flex flex-col md:ml-8 md:mt-32 w-32 justify-center">
            <TestimonialImage />
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default WohinDuWillst;
